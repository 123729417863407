import * as React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import { PageTitle } from '../components/PageTitles'
import RecordsFeed from '../components/RecordsFeed'
import Pagination from '../components/Pagination'
import YearFilters from '../components/YearFilters'

const RecordArchive = ({ data, pageContext }) => {

    const [buttonText, setButtonText] = React.useState(" ");

    // const recordMetaDesc = data.wp.seo.contentTypes.release.metaDesc;

    let scrollClones = 12;
    if (3 >= data.allWpArtist.nodes.length ) scrollClones = 15;
 

    return (
        <Layout>
             {/* <Seo title={`Records`} description={recordMetaDesc}  /> */}

                <PageWrapper scrollData={data.allWpArtist.nodes} clones={scrollClones}>

                <PageTitle className={`lg:!-mb-10`}>Releases</PageTitle>

                <YearFilters dropZone={`left`}  className={`md:absolute md:top-3 md:left-auto md:right-0`} buttonText={buttonText} setButtonText={setButtonText}  />

                {/* <RecordsFeed recordData={data.allWpRelease.edges} useList={true} useInitial={true} /> */}

                {/* <RecordsFeed recordData={data.allWpRelease.edges} className={`md:!grid !hidden`} /> */}
                <RecordsFeed.List data={data.allWpRelease.edges} useInitial={false} />

                <Pagination context={pageContext} />

                

                </PageWrapper>
        </Layout>
    )
}

export default RecordArchive

export const Head = ( { data } ) => <Seo2 title={`Records`} description={data?.wp?.seo?.contentTypes?.release?.metaDesc}  />

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWpRelease(
      skip: $skip,
      limit: $limit
      sort: {
        fields: date
        order: DESC
      }
      filter: { status: { eq: "publish" }}
    ) {
    edges {
        node {
          id
          title
          slug
          date
          ltaRecordsSettings {
            recordArtistSelection {
              ... on WpArtist {
                id
                title
                slug
              }
            }
            recordPreorderLink
            recordPurchaseLink
            recordStreamLink
            releaseDate
            lpNumber
          }
          imprints {
              nodes {
                  name
                  id
                  slug
                  count
              }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                      layout: FULL_WIDTH
                      aspectRatio: 1
                  )
                }
              }
            }
          }
        }
      }

      nodes {
          title
          id
          date(formatString: "Y")
          ltaRecordsSettings {
            releaseDate
          }
      }
  }
  
  allWpArtist(
      filter: {bioFeaturedVideos: {isLtaRecordsArtist: {eq: true}}, status: {eq: "publish"}}
      sort: {fields: slug, order: ASC}
      ) {
    nodes {
        slug
        title
        bioFeaturedVideos {
            isLtaRecordsArtist
          }
      }
  }
  wp {
    seo {
      contentTypes {
        release {
          metaDesc
        }
      }
    }
  }

}
`;